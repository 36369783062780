<template>
    <div class="py-5 px-10">
        <vs-alert v-if="$store.state.notification.success" relief class="mb-10">
            <template #title> Tình trạng cập nhập </template>
            Cập nhập thành công
        </vs-alert>
        <form-notify @save="submit" v-if="notify" v-model="notify" />
    </div>
</template>
<script>
import FormNotify from "../../originals/forms/FormNotify.vue";
export default {
    components: { FormNotify },
    props: ["id"],
    data() {

        return {
            notify: null,
        };
    },
    methods: {
        submit() {
            const form = new FormData();
            Object.keys(this.notify).map((key) => {
                const value = this.notify[key];
                if (value === null) return;
                switch (key) {
                    case "accounts":
                        if(typeof value === "object" && value.length){
                            value.map(val => form.append("accounts[]", val))
                        }
                        break;
                    default:
                        form.append(key, value);
                        break;
                }
            });
            this.$store.dispatch("notification/updateNotification", form);
        },
        setDefault() {
            this.notify = {
                is_active: true,
                accounts: [],
                channel: "notification",
                language: "vi-VN"
            };
        },
    },
    watch: {
        "notification.loading"(isLoading) {
            console.log("loading")
            if (isLoading) {
                this.loading = this.$vs.loading();
            } else if (this.loading) {
                this.loading.close();
            }
        },
        "notification.data"(value) {
            console.log(value)
            if (value && Object.keys(value).length) {
                if(`${value.id}` !== this.id){
                    return this.$router.push(`/admin/notifications/edit/${value.id}`);
                }
                this.notify = value;
            } else {
                this.setDefault();
            }
        },
    },
    computed: {
        notification(){
            return this.$store.state.notification;
        }
    },
    beforeMount() {
        if (this.id) {
            this.$store.dispatch("notification/loadNotification", {
                id: this.id,
            });
        } else {
            this.$store.commit("notification/reset");
            this.setDefault();
        }
    },
};
</script>
