<template>
    <div class="form-label">
        <p class="input-label">{{ label }}</p>
        <slot name="default"></slot>
    </div>
</template>
<script>
export default {
    props: ["label"],
};
</script>