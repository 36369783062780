<template>
    <div class="select-users">
        <div class="list" v-if="accounts">
            <label
                class="block"
                v-for="(account, index) in accounts"
                :key="index"
            >
                <input
                    v-model="value.accounts"
                    type="checkbox"
                    :value="account.username"
                />
                {{ account.fullname }}
            </label>
        </div>
        <button @click="loadAccounts">
            {{
                loading ? 'Đang tải... vui lòng chờ' : 'Nhấp vào đây để tải lại danh sách tài khoản'
            }}
        </button>
    </div>
</template>
<script>
export default {
    props: ["value"],
    data() {
        return {};
    },
    computed: {
        accounts() {
            return this.$store.state.accounts.data;
        },
        loading() {
            return this.$store.state.accounts.loading;
        },
    },
    methods: {
        fetchAccounts() {
            this.$store.dispatch("accounts/fetchAccounts");
        },
        loadAccounts() {
            this.$store.dispatch("accounts/loadAccounts");
        },
    },
    beforeMount() {
        if (!this.accounts || !this.accounts.length) {
            this.fetchAccounts();
        }
    },
};
</script>
<style>
.select-users {
    background: rgba(var(--vs-gray-2), 1);
    border-radius: 12px;
    padding: 20px;
}
.list {
    height: 325px;
    overflow-y: auto;
}
</style>