<template>
    <vs-row :w="12">
        <vs-col :w="8">
            <vs-input
                class="w-full mb-10"
                v-model="value.title"
                primary
                label="Tiêu đề"
            />
            <form-label label="Nội dung gửi">
                <textarea
                    rows="28"
                    placeholder="Nội dung"
                    v-model="value.content"
                ></textarea>
            </form-label>
        </vs-col>
        <vs-col :w="4" class="pl-2">
            <vs-button @click="$emit('save')" gradient class="mb-10"
                >LƯU LẠI</vs-button
            >
            <form-label label="Kênh thông báo">
                <vs-select
                    class="w-full mb-10"
                    placeholder="Chọn kênh thông báo"
                    v-model="value.channel"
                >
                    <vs-option
                        v-for="(type, index) in types"
                        :key="index"
                        :value="type.value"
                        :label="type.label"
                    >
                        {{ type.label }}
                    </vs-option>
                </vs-select>
            </form-label>
            <form-label label="Ngôn ngữ">
                <vs-select
                    class="w-full mb-10"
                    placeholder="Chọn ngôn ngữ"
                    v-model="value.language"
                >
                    <vs-option
                        v-for="(type, index) in languages"
                        :key="index"
                        :value="type.value"
                        :label="type.label"
                    >
                        {{ type.label }}
                    </vs-option>
                </vs-select>
            </form-label>
            <vs-checkbox
                :val="true"
                class="w-auto mb-10"
                v-model="value.is_active"
            >
                Cho phép gửi thông báo
            </vs-checkbox>
            <vs-input
                type="datetime-local"
                class="w-full mb-10"
                @change="value.date = $event.target.value"
                primary
                :value="
                    value.date
                        ? moment(value.date).format(
                              moment.HTML5_FMT.DATETIME_LOCAL
                          )
                        : null
                "
                label="Thời gian gởi"
            />
            <vs-input
                type="file"
                class="w-full mb-10"
                @change="changeImage($event)"
                primary
                label="Ảnh hiển thị"
            />
            <vs-checkbox
                :val="true"
                class="w-auto mb-10"
                v-model="value.is_send_all"
            >
                Gửi đến tất cả tài khoản
            </vs-checkbox>
            <form-label
                v-if="!value.is_send_all"
                label="Lựa chọn tài khoản (ctl+f để tìm kiếm)"
            >
                <select-users v-model="value" />
            </form-label>
        </vs-col>
    </vs-row>
</template>
<script>
import FormLabel from "../../../shareds/FormLabel.vue";
import SelectUsers from "../notify/SelectUsers.vue";
export default {
    components: { FormLabel, SelectUsers },
    props: ["value"],
    data() {
        return {
            types: [
                { value: "notification", label: "Thông báo" },
            ],
            languages: [
                { value: "vi-VN", label: "Tiếng việt" },
                { value: "en-US", label: "Tiếng anh" },
            ],
        };
    },
    methods: {
        changeImage($event) {
            if ($event.target.files[0]) {
                this.value.image = $event.target.files[0];
            }
        },
        change($event) {
            this.value.is_active = $event.target.checked;
        },
    },
};
</script>
