<template>
    <div class="w-full">
        <form-notify-container :id="$route.params.id" />
    </div>
</template>
<script>
import FormNotifyContainer from '../../components/containers/forms/FormNotifyContainer.vue';
export default {
    components: { FormNotifyContainer },
    data(){
        return {};
    }
}
</script>